import React from 'react';
import * as Sentry from "@sentry/browser";
import CookieService from "App/Services/CookieService";
import SharpstarServiceHandler from "App/Services/Handlers/SharpstarServiceHandler";
import { getLocalStorageVal } from "App/Helpers/getLocalStorageVal";
import SentryConfig from "App/Helpers/SentryConfig";
import { environment } from "App/Helpers/enviroments";
import UrlParams from "App/Helpers/UrlParams";
import Redirect from "App/Helpers/Redirect";
import config from "Pages/aq/casino/ro/casino7/config";
import Loader from "App/React/_UI/Loader/Loader";
import Header from "./components/Header";
import Main from "./components/Main";
import CasinoFooter from "./components/CasinoFooter";
import "./main.scss";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: null,
        };

        this.setUrlParam = this.setUrlParam.bind(this);
        this.abortController = new AbortController();
        this.setUrlParamValues(); // Set refids, hash, rid
        this.initServices(); //Init analytics
    }

    setUrlParam(name) {
        if (this[name] !== '0') { // If exists save it to local storage.
            localStorage.setItem(name, this[name]);
        } else if (getLocalStorageVal(name)) {  // Else get from local storage and set
            this[name] = getLocalStorageVal(name);
        }
    }

    setUrlParamValues() {
        this.refid3 = UrlParams.refid3;
        this.refid2 = UrlParams.refid2;
        this.refid1 = UrlParams.refid1;
        this.hash = UrlParams.hash;
        this.rid = UrlParams.rid;

        this.setUrlParam('refid3');
        this.setUrlParam('refid2');
        this.setUrlParam('refid1');
        this.setUrlParam('hash');
        this.setUrlParam('rid');
    }

    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.source,
            config.campaignId,
            new CookieService(config.cookieDomain)
        );

        SentryConfig.init(
            config.sentryEnabled,
            null,
            config.source,
            config.campaignId,
            null,
            document.location.host,
            environment(),
            this.refid3
        );
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', () => {
            Sentry.addBreadcrumb({
                level: 'info',
                message: 'user closes the page'
            });
            this.abortController.abort();
        });

        this.sharpstarDataService.getOffers(undefined, 0, this.rid)
            .then((res) => {
                if (!res.offers.length)  {
                    Redirect.redirect(`${config.fallbackUrl}?refid1=${this.refid1}`, environment());
                } else {
                    this.setState({
                        offers: res.offers
                    });
                }
            })
            .catch((e) => {
                Redirect.redirect(`${config.fallbackUrl}?refid1=${this.refid1}`, environment());
                Sentry.captureException(e);
            });
    }

    render() {
        if (!this.state.offers) {
            return <Loader color={'$btn-green'} backgroundColor={'#CEDCF2'}/>;
        }

        return (
            <div className={'app-wrapper'}>
                <Header urlParam={this.refid1} />
                <Main offers={this.state.offers} />
                <CasinoFooter urlParam={this.refid1} />
            </div>
        );
    }
}

export default App;
