import {environment} from '@/Helpers/enviroments';

const dev = {
    campaignId: 21,
    source: 'Casino7 RO Rt Dev',
    fallbackUrl: 'https://casino7.ro/',
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    modalsUrl: 'https://casino7.ro/wp-json/wp/v2/pages/?slug=',
    cookieDomain: 'https://lpdev.sharpstar.pro/',
    sentryEnabled: true,
    lang: 'ro',
    onesignalEnabled: false,
    onesignalAppId: "",
};

const prod = {
    campaignId: 23,
    source: 'Casino7 RO RT',
    fallbackUrl: 'https://casino7.ro/',
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    modalsUrl: 'https://casino7.ro/wp-json/wp/v2/pages/?slug=',
    cookieDomain: 'https://lp.casino7.ro/',
    sentryEnabled: true,
    lang: 'ro',
    onesignalEnabled: false,
    onesignalAppId: "",
};

let config;

if (environment() === 'production') {
    config = prod;
} else if (environment() === 'development'){
    config = dev;
} else {
    config = dev;
}

export default config;

