import * as Sentry from "@sentry/browser";

export default class Redirect {

    static redirect(url, env) {
        if (env === 'production') {
            Redirect._addBreadCrumbs(url);
            Sentry.flush(2000).then(() => {
                window.location = url;
            });
        } else {
            console.error('should redirect to: ' + url);
        }
    }

    static _addBreadCrumbs(url) {
        Sentry.addBreadcrumb({
            category: 'redirect',
            message: 'redirect to: ' + url,
            level: 'info'
        });
    }
}