import React from 'react';
import logo from "../../assets/logo-main.svg";
import './style.scss';

const Header = ({urlParam}) => {
  return (
      <header className={'header'}>
          <div className={'container'}>
              <a href={`https://casino7.ro/?refid=${urlParam}`} className={'logo'}>
                  <img src={logo} alt="logo"/>
              </a>
          </div>
      </header>
  )
};

export default Header;