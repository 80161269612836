import React from 'react';
import PropTypes from "prop-types";
import suitcase from '../../assets/suitcase.png';
import star from '../../assets/star.svg';
import "./style.scss";

const Main = ({ offers }) => {
    const defaultOffer = offers[0];
    const recommendedOffers = offers.slice(1);

    return (
        <main className={'main'}>
            <div className={'container'}>
                <div className={'offers_default'}>
                    <div className={'offers_default__text'}>
                        <div className={'title'}>
                            <h1>
                                {defaultOffer.content.title_1}
                                <br/>
                                {defaultOffer.content.title_2}
                            </h1>
                        </div>
                        <div className={'subtitle'}>
                            {defaultOffer.content.subtitle}
                        </div>
                        <a href={defaultOffer.link} className={'button'}>
                            {defaultOffer.content.button}
                        </a>
                    </div>
                    <div className={'offers_default__img'}>
                        <img src={suitcase} alt="suitcase with chips"/>
                    </div>
                </div>
                <div className={'offers_recommended'}>
                    <div className={'subtitle'}>
                        Mai multe cazinouri recomandate
                    </div>
                    {
                        recommendedOffers.map((offer) => {
                            const { id, content, link } = offer;

                            return (
                                <div key={id} className={'offers_recommended__item'}>
                                    {content.badge &&
                                        <div className={'offers_recommended__badge'}>
                                            <img src={content.badge} alt="badge"/>
                                        </div>
                                    }
                                    <div className={'offers_recommended__logo'}>
                                        <img src={content.logo} alt="logo"/>
                                    </div>
                                    <div className={'offers_recommended__title'}>
                                        {content.title_1}
                                        <br/>
                                        {content.title_2}
                                    </div>
                                    <div className={'offers_recommended__btn'}>
                                        <div className={'rating'}>
                                            {
                                                [...Array(content.rating ? +content.rating : 5).keys()]
                                                    .map((rate) => {
                                                        return  <img key={rate} src={star} alt="logo"/>
                                                    })
                                            }
                                        </div>
                                        <a href={link} className={'button offer-btn'}>
                                            {content.button}
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </main>
    )
};

export default Main;

Main.propTypes = {
    offers: PropTypes.array.isRequired,
};