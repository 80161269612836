import React from 'react';
import PropTypes from "prop-types";
import "./loader.scss";
import TextFit from "../../../textfit";

const Loader = (props) => (
    <div id="loader" style={{backgroundColor: props.backgroundColor}}>
        <strong className="loading" style={{background: props.color}}>Loading…</strong>

            <div style={{
                fontFamily: props.font ? props.font : "initial",
                color: props.color
            }} className="loader-text">
                <TextFit mode={'multi'}>
                {props.text}
                </TextFit>
            </div>

    </div>
);

Loader.proptypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string
};

export default Loader;