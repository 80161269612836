import React from 'react';
import oninIcon from '../../../assets/ongn.svg';
import adultIcon from '../../../assets/logo-18+.svg';
import josResponcabilIcon from '../../../assets/joc-responcabil-logo.svg';
import "./style.scss";

const FooterIcons = () => {
    return (
      <div className="footer-icons">
          <div>
              <img src={oninIcon} alt="onin company logo" />
          </div>
          <div className={'link'} onClick={() => window.open('https://jocresponsabil.ro/', '_blank')}>
              <img src={josResponcabilIcon} alt={'jos responcabil logo'} />
          </div>
          <div>
              <img src={adultIcon} alt="+18 sign" />
          </div>
      </div>
  )
};

export default FooterIcons;