import React, {Component} from 'react';
import { Textfit } from "react-textfit";
import * as Sentry from "@sentry/browser";
import WebsiteDataService from "App/Services/WebsiteDataService";
import polyglot from "App/Helpers/languages";
import FooterIcons from "./FooterIcons";
import Modal from "App/React/_UI/Modal/Modal";
import config from "../../config";
import footerLogo from "../../assets/logo-footer.svg";
import "./style.scss";

export default class CasinoFooter  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalText: {
                title: '',
                content: ''
            },
            modalOpen: false
        }

        this.websiteDataService = new WebsiteDataService(config.modalsUrl, config.lang);

        this.toggleModal = this.toggleModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modalOpen: false,
            modalText: {
                title: '',
                content: ''
            }
        });
    }

    showModal(type) {
        this.setState({
            modalOpen: true,
        });

        console.log(type);

        this.websiteDataService.getText(type)
            .then((res) => {
                console.log(res);
                this.setState({
                    modalText: res
                });
            })
            .catch((e) => {
                Sentry.captureException(e);
            });
    }

    render() {
        return (
            <div className={'footer'}>
                <div className={'container'}>
                    <div className={'footer-content'}>
                        <Textfit mode={'multi'} max={16} min={14}>
                            <div>
                                TERMENII ȘI CONDIȚIILE CARE SE APLICĂ PE SITE
                                <br/>
                                Jucătorii trebuie să aibă vârsta de minim 18 ani sau mai mult și să fie localizați în România.
                                <br/>
                                Susținem și promovăm jocurile de noroc în mod responsabil. Pentru mai multe informații accesează:
                            </div>
                            <FooterIcons />
                            <div>
                                Casino7 este un site deținut și operat independent. Nu suntem un operator de jocuri de noroc.
                            </div>
                            <div>
                            <span className={'link'} onClick={() => this.showModal('despre-noi')}>
                                {polyglot.t(config.lang + '.footer_links.about_us')}
                            </span> |
                                &nbsp;
                                <span className={'link'}
                                      onClick={() => this.showModal('politica-de-confidentialitate')}>
                                {polyglot.t(config.lang + '.footer_links.privacy_policy')}
                            </span> |
                                &nbsp;
                                <span className={'link'} onClick={() => this.showModal('termenii-de-utilizare')}>
                                {polyglot.t(config.lang + '.footer_links.terms')}
                            </span>
                            </div>
                            <div className={'copyright'}>
                                <div>
                                    Decizie ONJN Nr.354/26.02.2020
                                </div>
                                © Drepturi de autor. Toate drepturile rezervate. Casino7
                            </div>
                        </Textfit>
                    </div>
                </div>

                <a href={`https://casino7.ro/?refid=${this.props.urlParam}`} className={'footer-logo'}>
                    <img src={footerLogo} alt="logo"/>
                </a>

                {this.state.modalOpen &&
                    <Modal
                        title={this.state.modalText.title}
                        content={this.state.modalText.content}
                        onClose={this.toggleModal}
                    />
                }
            </div>
        )
    }
};
